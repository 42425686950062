import { addDays, addHours, addMinutes, startOfDay } from 'date-fns';


// TODO: По возможности подумать над временем и поясами.

export module IcuDay {
  let startOfDayOffset = 9 * 60;

  export function setStartOfDayOffset(valueMinutes: number) {
    startOfDayOffset = valueMinutes;
  }

  export function startOfReanimationDay(date: Date): Date {
    let result: Date;
    if (date.getHours() >= 0 && date.getHours() < startOfDayOffset / 60) {
      result = addMinutes(addDays(startOfDay(date), -1), startOfDayOffset);
    } else {
      result = addMinutes(startOfDay(date), startOfDayOffset);
    }
    console.log(result);
    return result;
  }

  export function endOfReanimationDay(date: Date): Date {
    if (date.getHours() >= 0 && date.getHours() < startOfDayOffset / 60) {
      return addMinutes(startOfDay(date), startOfDayOffset);
    }

    return addMinutes(addDays(startOfDay(date), 1), startOfDayOffset);
  }

}
